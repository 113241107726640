 // App.js
import React, { useEffect } from 'react';
import { useAuth } from './Contexts/UserContext'; // Ensure this path is correct
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import './index.css';

import LoginSection from './Authentication/LoginSection';

import LandingPage from './Landing/LandingPage';

import FuckYou from './fuck_you_interface/FuckYou'
import { auth } from './firebase-config'
import Leaderboard from './leaderbaord/Leaderboard';

function App() {
    console.log(auth)
    const user = useAuth()
    const location = useLocation()
    const navigate = useNavigate()
    console.log(user)
   
    return (
        <div className=""> {/* This applies the black background to the whole page */}
                <Leaderboard/>
                <Routes>
                    <Route path="/" element={<LandingPage/>}/>
                    <Route path="/login" element={<LoginSection />} />
                    <Route path="/fuck-you" element={<FuckYou/>} /> 
                </Routes>
                
        </div>
    
    );
}

export default App;
 