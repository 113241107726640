import React, { useState, useEffect } from "react";
import { useAuth } from "../Contexts/UserContext";
import { doc, updateDoc, increment, getDoc } from 'firebase/firestore';
import { db } from "../firebase-config";
import BalanceStats from "./BalanceStats";
import sound from "../assets/retard_audio.mp3"
import Leaderboard from "../leaderbaord/Leaderboard";
const FuckYou = () => {
    const [userInfo, setUserInfo] = useState({ number_of_fucks: 0, total_fucks_given : 0});
    const [clicks, setClicks] = useState([]); // Array to hold positions of clicks
    const [inputValue, setInputValue] = useState('');
    const user = useAuth();
    const [isModalVisible, setIsModalVisible] = useState(false);

    function play() {
        new Audio(sound).play()
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userRef = doc(db, "users", user?.user?.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    const userInfo = userDoc.data();
                    setUserInfo(userInfo);
                    // Assuming you have a state variable for tracking total fucks spent, like this:
                    // const [totalFucksSpent, setTotalFucksSpent] = useState(0);
                    // Then you update it here:
                } else {
                    console.log("No such document!");
                    // Handle the scenario where the document does not exist.
                    // This could include setting default state, user notifications, etc.
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
    
        if (user?.user?.uid) {
            fetchData();
        }
    }, [user?.user?.uid]);

    const update_fucks_left = async () => {
        if (userInfo?.number_of_fucks > 0) {
            try {
                // Decrement the fucks left
                setUserInfo((prevUserInfo) => ({
                    ...prevUserInfo,
                    number_of_fucks: prevUserInfo.number_of_fucks - 1,
                    total_fucks_given : prevUserInfo.total_fucks_given + 1
                }));
                const userRef = doc(db, "users", user?.user?.uid);
                await updateDoc(userRef, {
                    number_of_fucks: increment(-1),
                    total_fucks_given : increment(1)
                });
    
                // Increment the total fucks spent
              
                   
            } catch (error) {
                console.error("Error updating document: ", error);
            }
        }
    };
    const handleScreenClick = (event) => {
        if (isModalVisible || userInfo.number_of_fucks <= 0) {
            return;
        }
        // Add new click to the array of clicks with x and y coordinates
        setClicks((prevClicks) => [
            ...prevClicks,
            { x: event.clientX, y: event.clientY }
        ]);
        play()

        // Call the update function
        update_fucks_left();
    };
    const renderClickTexts = clicks.map((click, index) => (
        <div
            key={index}
            className="absolute"
            style={{
                left: `${click.x}px`,
                top: `${click.y}px`,
                position: 'fixed',
                zIndex: '10',
                userSelect: 'none', // Prevents text selection.
            }}
            onClick={handleScreenClick} // Allows clicks to register on this area.
        >
            retard
        </div>
    ));

    const handleSubmit = async () => {
        // FastAPI endpoint URL for creating a Stripe Checkout Session
        const endpoint = 'https://fastapi-project-pkratz4gma-uc.a.run.app/create-checkout-session';
        const test_endpoint = 'http://127.0.0.1:8000/create-checkout-session'
    
        try {
            const amount = parseInt(inputValue); // Convert input value to an integer
            if (isNaN(amount) || amount <= 0) {
                alert("Please enter a valid positive number.");
                return;
            }
            console.log(amount)
            console.log(user.user.uid)
    
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ amount: amount, user_id : user.user.uid }), // Send amount to the backend
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            // Extract the URL to redirect to the Stripe Checkout page
            const data = await response.json();
            window.location.href = data.checkout_url; // Redirect to Stripe Checkout
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    
    return (
        <div className="relative w-full h-full">
            
            {/* Position the BalanceStats component */}
            <div className="fixed top-0 right-0 z-50 flex flex-col items-end p-4">
                <BalanceStats userInfo={userInfo}  />
        
                {/* Input field and button for submitting number */}
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    className="mt-2 p-1"
                    placeholder="enter a number"
                />
                <button
                    onClick={handleSubmit}
                    className="mt-2 bg-blue-500 text-white p-2"
                >
                    buy more 
                </button>
            </div>
    
            {/* Full-screen clickable area */}
            <div className="fixed inset-0 z-0" onClick={handleScreenClick} />
    
            {/* Rendered "fuck you" texts */}
            {renderClickTexts}
    
            {/* Other content can go here */}
        </div>
    );
};

export default FuckYou;
