const BalanceStats = ({ userInfo, totalFucksSpent }) => {
    return (
        <div className="flex flex-col items-center">
            <div className="flex flex-row">
                <h1 className=" mr-2">username :</h1>
                <h1 className=" font-bold">{userInfo.username}</h1>
            </div>
          
        
        <div className="bg-slate-200 pl-5 pr-5 pb-5 pt-5">
            
            <div className="bg-slate-200 rounded">
               
                <div className="flex flex-col">
                    <h1>Balance: {userInfo.number_of_fucks}</h1>
                </div>
                <div>
                    <h1>Retard counter : {userInfo.total_fucks_given}</h1>
                </div>
            </div>
        </div>
        </div>
    );
};


export default BalanceStats;
