import React, { useState, useEffect } from 'react';
import { signInWithPopup, GoogleAuthProvider, getAdditionalUserInfo } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Contexts/UserContext';
import { auth } from '../firebase-config';

const LoginSection = () => {
    const [username, setUsername] = useState("");
    const [loginError, setLoginError] = useState("");
    const [isUsernameValid, setIsUsernameValid] = useState(false);

    const { setUser } = useAuth();
    const navigate = useNavigate();

    const handleUsernameChange = (event) => {
        const input = event.target.value;
        // Remove spaces from input and limit the length to a specific value, e.g., 15 characters
        const sanitizedInput = input.replace(/\s/g, '').slice(0, 15);
        setUsername(sanitizedInput);
    };

    const loginWithGoogle = async () => {
        if (!username.trim()) {
            setLoginError("Please enter a username without spaces and up to 15 characters long.");
            return;
        }

        try {
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({ prompt: 'select_account' });
            const result = await signInWithPopup(auth, provider);
            const info = getAdditionalUserInfo(result);
            const isNewUser = info.isNewUser;
            
            // Check if the user is new and proceed with registration
            if (isNewUser) {
                const response = await fetch('https://fastapi-project-pkratz4gma-uc.a.run.app/register', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        email: result.user.email,
                        user_id: result.user.uid,
                        username: username // Including the sanitized username in the payload
                    }),
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }
                setUser(result.user); // Set the signed-in user
            } else {
                setUser(result.user);
            }
            navigate('/fuck-you'); // Adjust the navigation route as needed
        } catch (error) {
            console.error('Login with Google error:', error);
            setLoginError(error.message);
        }
    };

    useEffect(() => {
        const checkUsername = async () => {
            if (!username.trim()) return;
    
            try {
                const response = await fetch(`https://fastapi-project-pkratz4gma-uc.a.run.app/check-username?username=${username}`);
                const data = await response.json();
    
                if (data.isUnique) {
                    setIsUsernameValid(true);
                    setLoginError("");
                } else {
                    setIsUsernameValid(false);
                    setLoginError("Can't use an existing username.");
                }
            } catch (error) {
                console.error('Error checking username:', error);
                setIsUsernameValid(false);
                setLoginError("Failed to check username. Try again.");
            }
        };
    
        checkUsername();
    }, [username]);

    return (
        <div className="flex items-center justify-center h-screen">
            <div className='flex flex-col justify-center items-center p-10 border-gray-300 border-2 rounded'>
                <div className=' flex flex-col items-center'>
                    <div className=' flex flex-col justify-center items-center mb-8'>
                        <p className='text-2xl font-semibold'>username here tard </p>
                        <p className=' text-xs'>{"(if you already made your account just type anything then login with your google account)"}</p>
                    </div>
                   
                    {loginError && <p className="text-red-500 mb-2">{loginError}</p>}
                </div>
                <input
                    type="text"
                    value={username}
                    onChange={handleUsernameChange}
                    placeholder="Username"
                    className="mb-4 px-4 py-2 border-gray-300 border-2 rounded"
                />
                <button
    onClick={loginWithGoogle}
    disabled={!isUsernameValid || username.trim() === ''}
    className={`px-4 py-2 flex flex-row items-center justify-center gap-2 rounded-lg transition duration-150 md:w-96 ${isUsernameValid && username.trim() !== '' ? 'bg-gray-600 hover:bg-gray-800  text-white' : 'bg-gray-200 text-gray-400 cursor-not-allowed'} ${!isUsernameValid || username.trim() === '' ? 'opacity-50 grayscale' : ''}`}
>
    <img className={`w-6 h-6 ${!isUsernameValid || username.trim() === '' ? 'grayscale' : ''}`} src="https://www.svgrepo.com/show/475656/google-color.svg" alt="Google logo"/>
    <span>then login here</span>
</button>
            </div>
        </div>
    );
};

export default LoginSection;
