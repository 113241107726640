import React, { useState, useEffect } from 'react';
import { db } from "../firebase-config"; // Ensure this path is correct
import { collection, getDocs, query, orderBy } from 'firebase/firestore';

const Leaderboard = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            // Define the collection you're fetching from
            const usersCol = collection(db, 'users');
            // Create a query against the collection, ordering by total_fucks_given in descending order
            const q = query(usersCol, orderBy('total_fucks_given', 'desc'));
            
            // Execute the query
            const querySnapshot = await getDocs(q);
            const usersData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setUsers(usersData);
        };

        fetchUsers();
    }, []);

    return (
        <div className="leaderboard fixed top-0 left-0 mt-4 ml-4 w-64 h-64 z-50">
            <h2 className="text-lg font-bold text-center mb-2">Leaderboard</h2>
            <div className="overflow-auto bg-white shadow rounded-lg p-2 h-full">
                {users
                    .sort((a, b) => b.total_fucks_given - a.total_fucks_given)
                    .map((user, index) => (
                        <div key={user.id} className="flex justify-between items-center border-b last:border-b-0 p-1">
                            <p className="text-xs font-medium">{index + 1}. {user.username || 'Anonymous'}</p>
                            <span className="text-xs font-semibold">{user.total_fucks_given}</span>
                        </div>
                    ))}
            </div>
        </div>
    );
    
};

export default Leaderboard;
