import React, { useState, useEffect, createContext, useContext } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase-config';
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from '../firebase-config'; // Assuming db is your Firestore instance


export const UserContext = createContext(null);

export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (!currentUser) {
                setUser(null)
                setInitialized(true)
            } else {
                setUser(currentUser)
                setInitialized(true)
            }
        });

        return unsubscribe; // Cleanup subscription on unmount
    }, []);

    const value = {
        user,
        initialized,
        setUser,
        // You can add other authentication-related functions here
    };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export const useAuth = () => { 
    const context = useContext(UserContext);
        if (context === undefined) {
            throw new Error('useAuth must be used within an AuthProvider');
        }
    
        return context;

}

export const useUser = () => {
    const { user } = useAuth();
    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let unsubscribe = () => {}; // Define an empty unsubscribe function

        if (user?.uid) {
            const userRef = doc(db, "users", user.uid);

            // Set up the real-time listener
            unsubscribe = onSnapshot(userRef, (doc) => {
                if (doc.exists()) {
                    setUserInfo(doc.data());
                } else {
                    console.log("No additional user data found in Firestore");
                }
                setLoading(false);
            }, (error) => {
                console.error("Error fetching user data:", error);
                setLoading(false);
            });
        }

        return () => unsubscribe(); // Clean up the listener when the component unmounts or dependencies change
    }, [user?.uid]);

    return { userInfo, loading };
};
