import React from 'react'
import { Link } from 'react-router-dom'
import LoginSection from '../Authentication/LoginSection'

 const LandingPage =  () => { 

    return (
        <div class=" flex flex-col items-center p-10 sm:p-20 lg:p-30 xl:p-40 sm:mb-10 xl:mb-30 min-h-screen">
            <div class="text-center mt-14 mb-10">
                <h1 class="text-6xl font-bold">retard with money?</h1>
            </div>
            <div class="text-center mt-20">
                <Link className=' bg-black rounded p-5 text-white font-bold' to={"/login"}> WASTE IT HERE </Link>
            </div>
        </div>


    )
}

export default LandingPage